import moment from "moment-timezone";
import {
  safeHavenClosed,
  safeHavenOpen,
} from "../../../app/slices/main/hoursOfOperation";

import { consoleLogIt } from "../../../app/log";

export const checkTimeForClosings = (dispatch: any) => {
  moment.tz.setDefault("America/New_York");

  if (moment().day() > 0 && moment().day() < 6) {
    if (moment().hour() > 9 && moment().hour() < 19) {
        dispatch(safeHavenOpen());
    } else {
        dispatch(safeHavenClosed());
    }
  } else {
      dispatch(safeHavenClosed());
  }
};
